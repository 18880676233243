<template>
    <inline-edit  
        :target="config.copyright" 
        element="p"
    />
</template>

<script>
      
    export default {
        props:["config"],
        mounted(){
            if(!this.config.copyright){
                this.config["copyright"] = ""
            }
            if(typeof this.config.copyright === 'string'){
                this.config.copyright = {value: this.config.copyright}
            }
        }
    }
      
</script>